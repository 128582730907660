import HTTP from "@/service/http";

export default {
  namespaced: true,
  state: {
    users: [],
  },
  actions: {
    getUsers({ commit }) {
      return new Promise((resolve, reject) => {
        HTTP()
          .get("/users")
          .then((resp) => {
            commit("setUsersList", resp.data);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    createUser(s, data) {
      return new Promise((resolve, reject) => {
        HTTP()
          .post("/users", data)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    updateUser(s, data) {
      return new Promise((resolve, reject) => {
        HTTP()
          .patch(`/users/${data.id}`, data)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    pwdSignup(s, data) {
      return new Promise((resolve, reject) => {
        HTTP(true)
          .post("/users/create-user", data)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  mutations: {
    setUsersList: (state, data) => {
      state.users = data.data;
    },
  },
  getters: {
    usersList: (state) => {
      return state.users ? state.users : [];
    },
  },
};
