<template>
  <div class="header flex align-items-center" style="background-color: #fcd535">
    <!-- <Sidebar v-model:visible="visibleFull" position="full">
      <Menu :model="items" class="menutest" />
    </Sidebar>
    <Button icon="pi pi-bars" @click="visibleLeft = true" /> -->
    <div class="flex-none ml-3"
    v-if="windowWidth < 760"
    >
      <button><span class="pi pi-bars icons-header"></span></button>
    </div>
    <div class="flex-grow-1 flex align-items-center justify-content-center">
      <img
        src="/layout/images/Logotiquera.png"
        alt="Logotiquera"
        class="img-tiquera"
      />
    </div>
    <div class="flex-none mr-3"
    v-if="windowWidth < 760"
    >
      <button><span class="pi pi-mobile icons-header"></span></button>
    </div>
  </div>
  <div
    class="enterprise-info"
    style="background-color: #404040; color: #fff"
    :class="[windowWidth > 760 ? 'enterprise-info' : 'enterprise-info-mobile']"
  >
  <div class="flex flex-wrap justify-content-center gap-4 sm:gap-4" v-if="windowWidth > 760">
    <div class="mt-4">
      <img src="/images/game/logo_monagas_innova.png" alt="" width="90px" />
    </div>
    <div class="mt-4">
      <h4>Empresa: TEC-FIN212, C.A</h4>
      <div>Proyecto: Sistema Pagando</div>
      <div>Nombre : Richard Orea</div>
      <div>Email: info@tecfin212.com</div>
      <div>Teléfono: +58 414-9958977</div>
    </div>
    <div class="mt-4">
      <div>Sponsor Peta</div>
      <div>Tipos de Entrada: PALCO</div>
      <div>Cantidad de asientos:</div>
    </div>
  </div>
</div>
  <div class="flex align-items-center justify-content-center"
  :class="[windowWidth > 760 ? '' : 'body']"
  >
    <SponsorRegistration />
  </div>
  <div class="flex align-items-center justify-content-center footer"
  :class="[windowWidth > 760 ? 'footer' : 'footer-mobile']"
  >
    <h6 class="" style="color: #ffff">Copyright Tiquera.com</h6>
  </div>
</template>
<script>
import SponsorRegistration from "./SponsorRegistration.vue";
export default {
  components: {
    SponsorRegistration,
  },
  data() {
    return {
      option_selected: null,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      options: [
        { name: "Entrada PALCO I" },
        { name: "Entrada PALCO II" },
        { name: "Entrada AUXILIAR I" },
        { name: "Entrada AUXILIAR II" },
        { name: "Entrada AUXILIAR III" },
      ],
      items: [
        {
          label: "Entrada Palco I",
          class: "active",
          command: (event) => {
            this.items.forEach((e) => {
              e.class = "";
            });
            event.item.class = "active";
            this.$parent.selected_option = "Entrada Palco I";
          },
        },
        {
          label: "Entrada Palco II",
          command: (event) => {
            this.items.forEach((e) => {
              e.class = "";
            });
            event.item.class = "active";
            this.$parent.selected_option = "Entrada Palco II";
          },
        },
        {
          label: "Entrada Auxiliar I",
          command: (event) => {
            this.items.forEach((e) => {
              e.class = "";
            });
            event.item.class = "active";
            this.$parent.selected_option = "Entrada Auxiliar II";
          },
        },
        {
          label: "Entrada Auxiliar II",
          command: (event) => {
            this.items.forEach((e) => {
              e.class = "";
            });
            event.item.class = "active";
            this.$parent.selected_option = "Entrada Auxiliar II";
          },
        },
        {
          label: "Entrada Auxiliar III",
          command: (event) => {
            this.items.forEach((e) => {
              e.class = "";
            });
            event.item.class = "active";
            this.$parent.selected_option = "Entrada Auxiliar III";
          },
        },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },

  beforeUnmount() { 
    window.removeEventListener('resize', this.onResize); 
  },

  methods: {  
    onResize() {
      this.windowWidth = window.innerWidth
      this.windowHeight = window.innerHeight
      
    }
  }
};
</script>
<style scoped>
.header {
  height: 100px;
}
.img-tiquera {
  height: 80px;
}
.enterprise-info {
  height: 150px;
}
.enterprise-info-mobile{
  height: 0px;
}
.body{
  background-color: #404040;
  height: max-content;
}
.footer{
  color: #404040;
}
.footer-mobile{
  background-color: #404040;
  width: auto;
  height: 410px;
  border: 5px solid #404040;
  margin-top: -2px;
  color:aliceblue
}
h6{
  position: absolute;
  bottom: 0;
}
.icons-header{
  color: #404040;
  font-weight: 700;
  font-size: x-large;
}
</style>
