<template>
  <Message
    v-if="SystemNotification"
    :severity="SystemNotification.severity"
    :sticky="true"
    @close="$store.dispatch('changeSystemNotification', null)"
    class="system-nitification"
    >{{ SystemNotification.content }}</Message
  >
  <Toast />
  <!-- Landing v-if="$route.path === '/'" @change-theme="changeTheme" /-->
  <!--Login v-else-if="$route.path === '/login'" /-->
  <!-- SignUp v-else-if="$route.path === '/signup'" /-->
  <!-- PwdSignUp v-else-if="$route.path === '/pwdsignup'" /-->
  <Error v-if="$route.path === '/error'" />
  <NotFound v-else-if="$route.path === '/notfound'" />
  <Access v-else-if="$route.path === '/access'" />
  <RegisterTicketsInfo v-else-if="$route.path === '/register-tickets'" />
  <App v-else @change-theme="changeTheme" />
</template>

<script>
import App from "./App";
// import Landing from "./pages/Landing";
// import Login from "./pages/Login";
// import SignUp from "./pages/SignUp";
// import PwdSignUp from "./pages/PwdSignUp";
import Error from "./pages/Error";
import NotFound from "./pages/NotFound";
import Access from "./pages/Access";
import RegisterTicketsInfo from "./pages/RegisterTicketsInfo.vue";

export default {
  mounted() {
    window.addEventListener("resize", this.resize);
  },
  methods: {
    changeTheme(event) {
      let themeElement = document.getElementById("theme-link");
      themeElement.setAttribute(
        "href",
        themeElement
          .getAttribute("href")
          .replace(this.$appState.theme, event.theme)
      );
      this.$appState.theme = event.theme;
      this.$appState.darkTheme = event.dark;

      if (event.theme.startsWith("md")) {
        this.$primevue.config.ripple = true;
      }
    },
    resize() {
      this.$windowSize.width = window.innerWidth;
      this.$windowSize.height = window.innerHeight;
    },
  },
  watch: {
    "$store.getters.getSystem_message": {
      deep: false,
      handler(newVal) {
        if (newVal) this.$toast.add(newVal);
      },
    },
  },
  computed: {
    SystemNotification() {
      return this.$store.getters.getSystem_notification;
    },
  },
  components: {
    App,
    // Landing,
    // Login,
    // SignUp,
    // PwdSignUp,
    Error,
    NotFound,
    Access,
    RegisterTicketsInfo,
  },
};
</script>
