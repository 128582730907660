import HTTP from "@/service/http";

export default {
  namespaced: true,
  state: {},
  actions: {
    getTickets(_s, dni) {
      return new Promise((resolve, reject) => {
        HTTP(true)
          .get(`/tickets?dni=${dni}`)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    saveBraceletTicket(_s, data) {
      return new Promise((resolve, reject) => {
        HTTP(true)
          .post("/tickets/asign-bracelet-token", data)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  mutations: {},
  getters: {},
};
