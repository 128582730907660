import { createRouter, createWebHistory } from "vue-router";
// import Dashboard from "./components/Dashboard.vue";
import session from "./service/session";

const routes = [
   {
    path: "/",
    name: "landing",
    component: () => import("./pages/Landing.vue"),
  },
  {
    path: "/tickets",
    name: "tickets",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "verificationCode" */ "./pages/VerificationCode.vue"
      ),
  },
  {
    path: "/iframes/game",
    name: "iframeGame",
    component: () =>
      import(/* webpackChunkName: "iframes-game" */ "./pages/Iframes.vue"),
  },
  {
    path: "/register-tickets",
    name: "register-tickets",
    component: () => 
    import (/* webpackChunkName: "register-tickets" */ "./pages/RegisterTicketsInfo.vue"),
  },
  {
    path: "/asign-bracelet",
    name: "asign-bracelet",
    component: () => 
    import (/* webpackChunkName: "register-tickets" */ "./pages/AsignBracelet.vue"),
  },
  /* {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    meta: {
      requireAuth: true,
      title: "Inicio",
      roles: [
        "administrador",
        "analista-ldo",
        "operadora",
        "comercializadora",
        "taquilla",
      ],
    },
    beforeEnter: (to, from, next) => {
      let user = session.getUserData();
      if (user.rol === "taquilla")
        next({
          path: "/ticketoffice",
        });
      else next();
    },
  }, */
  /* {
    path: "/lottery",
    name: "lottery",
    component: () => import("./components/lottery/Lottery.vue"),
    requireAuth: true,
    meta: {
      requireAuth: true,
      title: "lottery",
      roles: [
        "administrador",
        "analista-ldo",
        "operadora",
        "comercializadora",
        "taquilla",
      ],
    },
  }, */
  {
    path: "/error",
    name: "error",
    component: () => import("./pages/Error.vue"),
  },
  {
    path: "/access",
    name: "access",
    component: () => import("./pages/Access.vue"),
  },
  {
    path: "/notfound",
    name: "notfound",
    component: () => import("./pages/NotFound.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/notfound",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title ? to.meta.title + " - " : ""}  Tiquera`;
  if (!to.meta.requireAuth) {
    if (
      (to.name === "login" || to.name === "landing") &&
      session.userIsLogged()
    ) {
      next({
        path: "/dashboard",
      });
    } else next();
  } else {
    if (!session.userIsLogged()) {
      next({
        path: "/login",
      });
    } else {
      let user = session.getUserData();
      if (to.meta.roles && to.meta.roles.includes(user.rol)) next();
      else
        next({
          path: "/access",
        });
    }
  }
});

export default router;
