import HTTP from "@/service/http";

export default {
  namespaced: true,
  state: {
    activeClientData: {},
    tickets: [],
    qrdata: "",
    person: "",
  },
  actions: {
    generateTicketsTypes({ commit }) {
      return new Promise((resolve, reject) => {
        HTTP(true, 8000)
          .get(`/tickets/types`)
          .then((resp) => {
            commit("setGenerateTicketsTypes", {
              tickets: resp.data.data,
            });
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    generateSponsorsTypes({ commit }) {
      return new Promise((resolve, reject) => {
        HTTP(true, 8000)
          .get(`/sponsors/types`)
          .then((resp) => {
            commit("setGenerateSponsorsTypes", {
              sponsors: resp.data.data,
            });
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    verifyTokenQr({ commit }, data) {
      return new Promise((resolve, reject) => {
        HTTP(true, 8000)
          .get(`/tickets/${data}`)
          .then((resp) => {
            commit("setVerifyTokenQr", {
              qrdata: resp.data.data,
            });
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    verifyPerson({ commit }, data) {
      return new Promise((resolve, reject) => {
        HTTP(true, 8000)
          .get(`persons/exists/${data}`)
          .then((resp) => {
            commit("setVerifyPerson", resp.data.data);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  mutations: {
    setGenerateTicketsTypes: (state, data) => {
      state.tickets = data;
    },
    setGenerateSponsorsTypes: (state, data) => {
      state.tickets = data;
    },
    setVerifyTokenQr: (state, data) => {
      state.qrdata = data;
    },
    setVerifyPerson: (state, data) => {
      state.person = data;
    },
  },
  getters: {},
};
