<template>
  <div class="card grid flex justify-content-center" v-if="windowWidth > 760">
    <div class="col-3 menu">
      <div
        class="menu-title text-center flex align-items-center justify-content-center"
      >
        Registro de Entradas
      </div>
      <!-- <div class="options">
        <Listbox
          v-model="option_selected"
          :options="options"
          optionLabel="name"
          class="list"
        />
      </div> -->
      <div class="menu-options">
        <Menu :model="items" class="inner-menu" />
      </div>
      <!-- <div class="optionsTest">
        <div class="test onselect m-2">Entrada PALCO I</div>
        <div class="test m-2">Entrada PALCO II</div>
        <div class="test m-2">Entrada AUXILIAR I</div>
        <div class="test m-2">Entrada AUXILIAR II</div>
        <div class="test m-2">Entrada AUXILIAR III</div>
      </div> -->
    </div>
    <div class="col-6 form">
      <div
        class="title-form text-center flex align-items-center justify-content-center"
      >
        Registro de entrada PALCO I
      </div>
      <div class="form-group">
        <span class="p-float-label mb-4 mt-4 mr-4 ml-4">
          <InputText class="w-full" autocomplete="off" maxlength="50" />
          <label :style="{ color: '#404040' }"
            >Documento de identidad *</label
          >
        </span>
        <span class="p-float-label mb-4 mr-4 ml-4">
          <InputText class="w-full" autocomplete="off" maxlength="20" />
          <label :style="{ color: '#404040' }">Nombre *</label>
        </span>
        <span class="p-float-label mb-4 mr-4 ml-4">
          <InputText class="w-full" autocomplete="off" maxlength="20" />
          <label :style="{ color: '#404040' }">Apellido *</label>
        </span>
        <span class="p-float-label mb-4 mr-4 ml-4">
          <InputText class="w-full" autocomplete="off" maxlength="50" />
          <label :style="{ color: '#404040' }"
            >Correo Electrónico *</label
          >
        </span>
        <span class="p-float-label mb-4 mr-4 ml-4">
          <InputText class="w-full" autocomplete="off" maxlength="50" />
          <label :style="{ color: '#404040' }">País *</label>
        </span>
        <span class="p-float-label mb-4 mr-4 ml-4">
          <InputText class="w-full" autocomplete="off" maxlength="50" />
          <label :style="{ color: '#404040' }">Teléfono *</label>
        </span>
      </div>
      <div class="footer-form">
        <div class="flex align-items-center justify-content-center">
          <button
            class="btn1 btn-primary"
            type="button"
            @click="$emit('cancel')"
          >
            Reenviar Entrada
          </button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="windowWidth < 760">
    <div v-if="this.showForm = true">
      <div class="name">
        <h4>Registro de</h4>
        <h2>ENTRADAS</h2>
      </div>
      <hr />
      <div class="flex flex-column">
        <button>Registro de entrada <b>PALCO I</b></button>
        <button>Registro de entrada <b>PALCO II</b></button>
        <button>Registro de entrada <b>AUXILIAR I</b></button>
        <button>Registro de entrada <b>AUXILIAR II</b></button>
        <button>Registro de entrada <b>AUXILIAR III</b></button>
      </div>
    </div>
    <div class="shadow-6 form form-mobile" v-if="this.showForm = false">
      <div
        class="title-form text-center flex align-items-center justify-content-center"
      >
        Registro de entrada PALCO I
      </div>
      <div class="form-group">
        <span class="p-float-label mb-4 mt-4 mr-4 ml-4">
          <InputText class="w-full" autocomplete="off" maxlength="50" />
          <label :style="{ color: '#404040' }"
            >Documento de identidad *</label
          >
        </span>
        <span class="p-float-label mb-4 mr-4 ml-4">
          <InputText class="w-full" autocomplete="off" maxlength="20" />
          <label :style="{ color: '#404040' }">Nombre *</label>
        </span>
        <span class="p-float-label mb-4 mr-4 ml-4">
          <InputText class="w-full" autocomplete="off" maxlength="20" />
          <label :style="{ color: '#404040' }">Apellido *</label>
        </span>
        <span class="p-float-label mb-4 mr-4 ml-4">
          <InputText class="w-full" autocomplete="off" maxlength="50" />
          <label :style="{ color: '#404040' }"
            >Correo Electrónico *</label
          >
        </span>
        <span class="p-float-label mb-4 mr-4 ml-4">
          <InputText class="w-full" autocomplete="off" maxlength="50" />
          <label :style="{ color: '#404040' }">País *</label>
        </span>
        <span class="p-float-label mb-4 mr-4 ml-4">
          <InputText class="w-full" autocomplete="off" maxlength="50" />
          <label :style="{ color: '#404040' }">Teléfono *</label>
        </span>
      </div>
      <div class="footer-form">
        <div class="flex align-items-center justify-content-center">
          <button
            class="btn1 btn-primary"
            type="button"
            @click="$emit('cancel')"
          >
            Reenviar Entrada
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      showForm: false,
      option_selected: null,
      windowWidth: window.innerWidth,
      options: [
        { name: "Entrada PALCO I" },
        { name: "Entrada PALCO II" },
        { name: "Entrada AUXILIAR I" },
        { name: "Entrada AUXILIAR II" },
        { name: "Entrada AUXILIAR III" },
      ],
      items: [
        {
          label: "Entrada Palco I",
          class: "active",
          command: (event) => {
            this.setSelectedOption(event);
          },
        },
        {
          label: "Entrada Palco II",
          command: (event) => {
            this.setSelectedOption(event);
          },
        },
        {
          label: "Entrada Auxiliar I",
          command: (event) => {
            this.setSelectedOption(event);
          },
        },
        {
          label: "Entrada Auxiliar II",
          command: (event) => {
            this.setSelectedOption(event);
          },
        },
        {
          label: "Entrada Auxiliar III",
          command: (event) => {
            this.setSelectedOption(event);
          },
        },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    setSelectedOption(event) {
        this.items.forEach((e) => {
            e.class = "";
        });
        event.item.class = "active";
        this.$parent.selected_option = event.item.label;
    },
  },
};
</script>
<style scoped>
.card {
  width: 80%;
  border-radius: 6px;
}
.menu-title {
  background-color: #404040;
  color: #fcd535;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border: 1px solid #404040;
  height: 40px;
  font-weight: 700;
  font-size: large;
}
.title-form {
  background-color: #fcd535;
  color: #404040;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  height: 40px;
  border: 1px solid #7d7d7d;
  font-weight: 700;
  font-size: large;
  border-bottom: none;
}
li:hover {
  background-color: #fcd535;
}
.list {
  color: #fcd535;
  font: 700;
}
.menu-options {
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.test:active {
  background: #fcd535;
}
.onselect {
  background: #fcd535;
}
.btn1 {
  width: 180px;
  height: 30px;
  background: #fcd535;
  color: #404040;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 700;
}
.footer-form {
  padding: 1rem;
  background: #e6e6e6;
  border: 1px solid #7d7d7d;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top: none;
}
.form-group {
  border: 1px solid #7d7d7d;
}
.menu {
  width: 250px;
}

.inner-menu {
  width: auto;
}
.name {
  margin-top: 50%;
}
button {
  color: #fcd535;
  border: 1px solid #fcd535;
  border-radius: 15px;
  margin: 1px;
}
hr {
  background-color: #7d7d7d;
}
h4 {
  color: #d7d7d7;
}
h2 {
  color: #fcd535;
}
.search-input {
  color: #7d7d7d;
  background-color: #d9d9d9;
  border-radius: 50px;
  width: 300px;
  height: 5px;
}

.form-mobile {
  background-color: #ffff;
  margin-top: 80px;
  width: 320px;
  border-radius: 15px;
}
</style>
