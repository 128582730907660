import axios from "axios";
import router from "../router";
import { store } from "../store";

export default (is_public = false, timeout = 6000, response_type = "json") => {
  let headers_config = {
    //"Access-Control-Allow-Origin": "*",
    // "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
    // "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, Accept",
    // "Content-Type": "application/json",
  };

  //add authorization header if not is a public request
  if (!is_public)
    headers_config.Authorization = `Bearer ${
      JSON.parse(localStorage.getItem(process.env.VUE_APP_SESSION_KEY))
        .access_token
    }`;

  const http = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    timeout: timeout,
    headers: headers_config,
    responseType: response_type
  });

  http.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response) {
        if (
          error.response.status === 401 &&
          router.currentRoute.value.name !== "login"
        ) {
          localStorage.removeItem("user_session");
          router.push("/access");
        } else if (error.response.status === 500) {
          store.dispatch("changeSystemNotification", {
            severity: "error",
            content: "Hubo un error interno en la Aplicación.",
          });
        } else {
          /* store.dispatch("changeSystemMessage", {
            severity: "error",
            summary: "Error",
            detail: error.response.data.errors[0].detail,
            life: 4000,
          }); */
          //return Promise.reject(error);
        }
      } else {
        console.log(error)
        /* store.dispatch("changeSystemMessage", {
          severity: "error",
          summary: "Network Error",
          detail: "El servidor no responde. Revise su conexión de Internet.",
          life: 4000,
        }); */
      }
      return Promise.reject(error);
    }
  );
  return http;
};
